<template lang="pug">
  l-layer-group(v-if="visibleTracks && settingsAUnit.renderingMode === 1")
    template(v-if="!Object.keys(clientTrackPeriod).length")
      l-layer-group(
        v-for="(clientTrack, id) in visibleClientTracks",
        :key="id")
        l-polyline(
          :lat-lngs="clientTrack.latlngs",
          :color="clientTrack.color",
          @click="clientTrackClick(clientTrack, id)")
        l-circle-marker(
          v-for="clusteredPoint in clientTrack.clusteredPoints",
          pane="overlayPane"
          :key="clusteredPoint[0]",
          :radius="3"
          :color="clientTrack.color"
          :lat-lng="[clusteredPoint[2], clusteredPoint[3]]",
        )
        l-layer-group(v-if="settingsAUnit.showParkingsInTracks")
          l-marker(
            v-for="parking in clientTrack.parkings",
            :key="parking[0]",
            :lat-lng="[parking[2], parking[3]]",
            :icon="parkingIcon")
        l-layer-group(v-if="settingsAUnit.showStopsInTracks")
          l-marker(
            v-for="stop in clientTrack.stops",
            :key="stop[0]",
            :lat-lng="[stop[2], stop[3]]",
            :icon="stopIcon")
    template(v-if="!displayMode")
      l-layer-group(
        v-for="period in visibleClientTrackPeriod",
        :key="period.id")
        l-polyline(
          :lat-lngs="period.latlngs",
          :color="period.color",
          @click="clientTrackClick(period.clientTrack, period.clientTrackId)")
      l-layer-group(v-if="settingsAUnit.showParkingsInTracks && clientTrackPeriod.period && clientTrackPeriod.period.visible")
        l-marker(
          v-for="parking in visibleClientTrackPeriod.period.parkings",
          :key="parking[0]",
          :lat-lng="[parking[2], parking[3]]",
          :icon="parkingIcon")
      l-layer-group(v-if="settingsAUnit.showStopsInTracks && clientTrackPeriod.period && clientTrackPeriod.period.visible")
        l-marker(
          v-for="stop in visibleClientTrackPeriod.period.stops",
          :key="stop[0]",
          :lat-lng="[stop[2], stop[3]]",
          :icon="stopIcon")
    template(v-else)
      l-layer-group(
        v-for="periods in visibleClientTrackPeriods",
        :key="periods.id")
        l-polyline(
          :lat-lngs="periods.latlngs",
          :color="periods.color",
          @click="clientTrackClick(clientTracks[clientTrackPeriods.id], clientTrackPeriods.id)")
      l-layer-group(v-if="settingsAUnit.showParkingsInTracks && clientTrackPeriods.visible")
        l-marker(
          v-for="parking in clientTrackPeriods.parkings",
          :key="parking[0]",
          :lat-lng="[parking[2], parking[3]]",
          :icon="parkingIcon")
      l-layer-group(v-if="settingsAUnit.showStopsInTracks && clientTrackPeriods.visible")
        l-marker(
          v-for="stop in clientTrackPeriods.stops",
          :key="stop[0]",
          :lat-lng="[stop[2], stop[3]]",
          :icon="stopIcon")
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import L from 'leaflet';

export default {
  name: 'ClientTracks',
  data () {
    return {
      parkingIcon: L.icon({
        iconUrl: '/img/icon_parkings/parking.png',
        iconSize: [30, 30],
      }),
      stopIcon: L.icon({
        iconUrl: '/img/icon_parkings/stops.png',
        iconSize: [30, 30],
      }),
    };
  },
  computed: {
    ...mapState('reporting', ['displayMode']),
    ...mapState('tracks', ['clientTracks', 'clientTrackPeriod', 'clientTrackPeriods']),
    ...mapGetters('tracks', ['visibleTracks', 'visibleClientTracks', 'visibleClientTrackPeriod', 'visibleClientTrackPeriods']),
    ...mapGetters('preferences', ['settingsAUnit']),
  },
  methods: {
    clientTrackClick (clientTrack, clientTrackId) {
      this.$emit('client-track-click', clientTrack, clientTrackId);
    },
  },
};
</script>

<style scoped>

</style>
